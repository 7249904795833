"use client";
import { IoPricetagsSharp } from "react-icons/io5";

type Props = {
  network: string;
  address: string;
  floorPriceUsd?: string | null;
};

export default function BtnShop({ network, address, floorPriceUsd }: Props) {
  return (
    <a
      className={`w-full flex flex-grow overflow-clip items-center justify-center rounded-lg bg-gradient-to-r from-primary to-[#970BD9] px-8 py-3 text-base font-medium 
    hover:brightness-110 focus:outline-none focus:ring-2 focus:ring-indigo-500 
    focus:ring-offset-2 focus:ring-offset-gray-50  font-500 transition  tracking-wide`}
      href={`/collections/${network}/${address}`}
    >
      <IoPricetagsSharp className="mr-2 text-[20px]" />
      Shop{" "}
      {floorPriceUsd && floorPriceUsd.length > 0 && `from ${floorPriceUsd}`}
    </a>
  );
}
