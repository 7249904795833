"use client";
import PrimaryButton from "../../Forms/Button/PrimaryBtn";

export default function BtnExplore() {
  const onClickExplore = () => {
    window.scrollTo({
      top: 700,
      behavior: "smooth",
    });
  };
  return (
    <div className="hidden sm:flex w-[160px] sm:min-w-[220px] lg:w-[300px] col-span-1">
      <PrimaryButton
        neon
        onClick={onClickExplore}
        style="h-[60px]  !uppercase w-full"
      >
        Explore
      </PrimaryButton>
    </div>
  );
}
