"use client";
import Link from "next/link";
import { IoPricetagsSharp } from "react-icons/io5";
import { getNetworkImage } from "@/utils/misc";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

type Props = {
  collections: any[];
  title: string;
};

export default function SliderClient({ title, collections }: Props) {
  const formatBanner = (url: string, newWidth = "w=1920") => {
    if (url?.includes("seadn.io") && url?.includes("w=500")) {
      return url?.replace("w=500", newWidth);
    }
    return url;
  };

  return (
    <div className="sm:pl-[40px] mx-auto pl-4">
      <div className="mb-[12px] text-left text-xl tracking-wide ">
        <span className="font-700 leading-[46px]">{title}</span>
      </div>

      {/* Mobile Swiper */}
      <div className="block lg:hidden mt-6">
        <Swiper
          spaceBetween={16}
          slidesPerView={"auto"}
          freeMode={true}
          grabCursor={true}
          className="mySwiper"
        >
          {collections.map((collectionData, index) => (
            <SwiperSlide
              key={collectionData.collection.address || index}
              style={{ width: "auto" }}
            >
              <Link
                href={`/collections/${collectionData.collection.network}/${collectionData.collection.address}`}
                className="group flex rounded-lg flex-col items-center justify-center relative"
                aria-label={collectionData.collection.name}
                style={{ width: "300px" }}
              >
                <div className="rounded-lg md:p-3 w-full">
                  {collectionData.image?.includes(".mp4") ? (
                    <video
                      src={collectionData.image}
                      autoPlay
                      loop
                      muted
                      playsInline
                      preload="auto"
                      className="w-full h-[220px] md:h-[270px] object-cover rounded-lg shadow-md group-hover:brightness-125 transition"
                    />
                  ) : (
                    <img
                      src={formatBanner(collectionData.image, "w=800")}
                      alt={`Banner image for ${collectionData.collection.name}`}
                      className="w-full h-[220px] md:h-[270px] object-cover rounded-lg shadow-md group-hover:brightness-125 transition"
                    />
                  )}

                  <p className="text-center mt-4 text-lg font-600 transition line-clamp-1">
                    {collectionData.collection.name}
                  </p>
                  <span
                    className="w-full text-center flex justify-center mt-2 font-600 bg-clip-text text-transparent 
                  bg-gradient-to-r from-primary to-[#970BD9] text-lg tracking-wide"
                  >
                    {collectionData.formattedUsdTV} in sales
                  </span>
                </div>
                <img
                  src={getNetworkImage(collectionData.collection.network)}
                  className="absolute top-6 left-5 object-contain w-[20px] h-[20px]"
                  alt={`Blockchain icon for ${collectionData.collection.network}`}
                />

                {collectionData.formattedUsdFloorPrice && (
                  <div className="px-3 w-full">
                    <div
                      className="mt-4 md:mt-2 lg:max-w-[320px] mx-auto flex overflow-visible items-center justify-center
                     rounded-lg px-8 py-3 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 
                     focus:ring-offset-gray-50 font-500 transition w-full text-base font-semibold bg-gradient-to-r
                      from-primary to-[#970BD9] group-hover:brightness-110"
                    >
                      <IoPricetagsSharp className="mr-2 text-[20px]" />
                      Shop{" "}
                      {collectionData.formattedUsdFloorPrice &&
                        collectionData.formattedUsdFloorPrice.length > 0 &&
                        `from ${collectionData.formattedUsdFloorPrice}`}
                    </div>
                  </div>
                )}
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {/* Desktop Version */}
      <div
        style={{ WebkitOverflowScrolling: "touch" }}
        className="mt-6 hidden lg:flex flex-row overflow-x-scroll scrollbar-hide gap-3 md:gap-0"
      >
        {collections.map((collectionData, index) => (
          <Link
            href={`/collections/${collectionData.collection.network}/${collectionData.collection.address}`}
            key={collectionData.collection.address || index}
            className={`scrollbar-hide flex group rounded-lg flex-col items-center justify-center relative ${
              index === collections.length - 1 && "sm:mr-[12px] mr-4"
            }`}
            aria-label={collectionData.collection.name}
          >
            <div className="rounded-lg md:p-3">
              {collectionData.image?.includes(".mp4") ? (
                <video
                  src={collectionData.image}
                  autoPlay
                  loop
                  muted
                  playsInline
                  preload="auto"
                  className="flex w-full h-[220px] md:min-h-[270px] min-w-[330px] md:min-w-[400px] object-cover rounded-lg shadow-md md:group-hover:brightness-125 transition"
                />
              ) : (
                <img
                  src={formatBanner(collectionData.image, "w=800")}
                  alt={`Banner image for ${collectionData.collection.name}`}
                  className="w-full h-[220px] md:min-h-[270px] min-w-[330px] md:min-w-[400px] object-cover rounded-lg shadow-md md:group-hover:brightness-125 transition"
                />
              )}

              <p className="text-center mt-4 md:group-hover:text-dark-pri text-lg font-600 transition line-clamp-1">
                {collectionData.collection.name}
              </p>
              <span className="w-full text-center flex justify-center md:mt-[2px] font-600 bg-clip-text text-transparent bg-gradient-to-r gradient-1 text-lg tracking-wide md:group-hover:text-dark-pri">
                {collectionData.formattedUsdTV} in sales
              </span>
            </div>
            <img
              src={getNetworkImage(collectionData.collection.network)}
              className="absolute top-6 left-5 object-contain w-[20px] h-[20px]"
              alt={`Blockchain icon for ${collectionData.collection.network}`}
            />

            {collectionData.formattedUsdFloorPrice && (
              <div className="px-3 w-full">
                <div className="mt-4 md:mt-2 lg:max-w-[320px] mx-auto flex overflow-visible items-center justify-center rounded-lg px-8 py-3 md:hover:brightness-110 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50 font-500 transition w-full text-base font-semibold bg-gradient-to-r from-primary to-[#970BD9] md:neonHover">
                  <IoPricetagsSharp className="mr-2 text-[20px]" />
                  Shop{" "}
                  {collectionData.formattedUsdFloorPrice &&
                    collectionData.formattedUsdFloorPrice.length > 0 &&
                    `from ${collectionData.formattedUsdFloorPrice}`}
                </div>
              </div>
            )}
          </Link>
        ))}
      </div>
    </div>
  );
}
