"use client";

import { HeartIcon } from "@heroicons/react/24/outline";
import Snackbar from "@mui/material/Snackbar/Snackbar";
import { useState } from "react";
import IconHeartSolid from "../../../../../../components/ui/Icons/IconHeartSolid";
import Alert from "@/components/ui/Alert";

type Props = {
  liked: boolean;
  walletAddress: string | undefined;
  likesCount: number;
};

export default function BtnHeart({ liked, walletAddress, likesCount }: Props) {
  const [likedOptimistic, setLikedOptimistic] = useState(liked);
  const [optimisticLikesCount, setOptimisticLikesCount] = useState(likesCount);
  const [snackbar, setSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("Like updated!");

  const handleClick = () => {
    if (!walletAddress || walletAddress.length === 0) {
      setSnackbarMessage("Please log in to like this NFT.");
      setSnackbar(true);
      return;
    } else {
      setLikedOptimistic(!likedOptimistic);
      setOptimisticLikesCount(
        likedOptimistic ? optimisticLikesCount - 1 : optimisticLikesCount + 1
      );
    }
  };
  return (
    <div className="flex items-center ">
      <button
        type="submit"
        className="ml-4 flex items-center justify-center rounded-md px-3 py-3"
        onClick={handleClick}
      >
        {likedOptimistic ? (
          <>
            <IconHeartSolid />
            <span className="sr-only">Unlike this NFT</span>
          </>
        ) : (
          <>
            <HeartIcon className="h-6 w-6  hover:text-dark-pri" />
            <span className="sr-only">Like this NFT</span>
          </>
        )}
      </button>
      {optimisticLikesCount > 0 && (
        <span className="text-sm">{optimisticLikesCount}</span>
      )}
      <Alert
        open={snackbar}
        close={() => setSnackbar(false)}
        message={snackbarMessage}
      />
    </div>
  );
}
